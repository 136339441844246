import React, { useEffect, useState } from 'react';
import { registerLocale } from 'react-datepicker';

import loadable from '@loadable/component';
import { ErrorMessage } from 'formik';

let DatePicker;
let setHours;
let setMinutes;

// Check in node-modules/date-fns/locale folder to see if we need to overwrite
const localeToDateFnsLocale = {
  'de-DE': 'de',
  'fr-FR': 'fr',
};

export const loadDateFnsLocale = async (locale) => {
  return await import(`date-fns/locale/${localeToDateFnsLocale[locale] ?? locale}/index.js`);
};

const getPlaceholderText = (locale) => {
  if (locale.startsWith('fr')) {
    return 'Date et heure';
  }

  return 'Date and time';
};

const addDays = (startDate, noOfDaysToAdd) => {
  let endDate;
  let count = 0;

  while (count < noOfDaysToAdd) {
    endDate = new Date(startDate.setDate(startDate.getDate() + 1));
    if (endDate.getDay() != 0 && endDate.getDay() != 6) {
      count++;
    }
  }

  return endDate;
};

const isWeekday = (date) => {
  const day = date.getDay();

  return day !== 0 && day !== 6;
};

const customFilter = (curDate, datesToRemove) => {
  datesToRemove = datesToRemove?.split(',');

  return !datesToRemove?.some((dateToRemove) => {
    const [
      year,
      month,
      date,
    ] = dateToRemove.split('-');

    return (
      curDate.getDate() === Number.parseInt(date) &&
      curDate.getMonth() + 1 === Number.parseInt(month) &&
      curDate.getFullYear() === Number.parseInt(year)
    );
  });
};

const DatePickerComponent = (props) => {
  const {
    attributes, errors, locale, setValues, touched, values,
  } = props;
  const {
    className,
    datesToRemove,
    innerClassName,
    isInline,
    maxDate,
    minDate,
    name,
  } = attributes;

  const [startDate, setStartDate] = useState(null);

  useEffect(() => {
    setValues(Object.assign(values, { [name]: '' }));

    DatePicker = DatePicker ?? loadable(() => import('react-datepicker'));

    // eslint-disable-next-line unicorn/prefer-module
    setHours = setHours ?? require('date-fns/setHours').default;

    // eslint-disable-next-line unicorn/prefer-module
    setMinutes = setMinutes ?? require('date-fns/setMinutes').default;

    // import(`react-datepicker/dist/react-datepicker.css`)

    loadDateFnsLocale(locale).then((data) => {
      if (!data) {
        return;
      }

      // Inject localeData during runtime
      window.__localeData__ = window.__localeData__ ?? {};
      window.__localeData__[locale] = data;
      registerLocale(locale, data.default);
    });
  }, [locale]);

  return (
    <div className={`reactDatePicker ${className}`}>
      {DatePicker && (
        <DatePicker

          // showDisabledMonthNavigation
          autocomplete="off"
          className={`field ${innerClassName}${
            errors[name] && touched[name] ? ' -error' : ''
          }`}
          dateFormat="PP p"
          filterDate={(date) =>
            isWeekday(date) && customFilter(date, datesToRemove)
          }
          inline={isInline || undefined}
          locale={locale}
          maxDate={maxDate ? addDays(new Date(), Number.parseInt(maxDate)) : undefined}
          maxTime={
            setHours ? setHours(setMinutes(new Date(), 0), 17) : undefined
          }
          minDate={minDate ? addDays(new Date(), Number.parseInt(minDate)) : undefined}
          minTime={
            setMinutes ? setHours(setMinutes(new Date(), 0), 9) : undefined
          }
          name={name}
          onChange={(val) => {
            if (
              !values.schedule_demo_datetime && val && val.getHours() !== 0 ||
              values.schedule_demo_datetime &&
                values.schedule_demo_datetime.getHours() === 0 &&
                val.getHours() !== 0 ||
              values.schedule_demo_datetime &&
                Math.abs(values.schedule_demo_datetime.getTime() - val.getTime()) <= 28_800_000
            ) {
              window.dataLayer.push({ event: 'time_selected' });
            }

            if (minDate) {
              const theMinDate = addDays(new Date(), Number.parseInt(minDate));
              theMinDate.setHours(0);
              theMinDate.setMinutes(0);

              if (theMinDate.getTime() > val.getTime()) {
                theMinDate.setHours(val.getHours());
                theMinDate.setMinutes(val.getMinutes());
                val = theMinDate;
              }
            }

            setValues(Object.assign(values, { [name]: val }));
            setStartDate(val);
          }}
          onChangeRaw={(event) => {
            if (
              event.target &&
              event.target.classList.contains('react-datepicker__day')
            ) {
              window.dataLayer.push({ event: 'date_selected' });
            }
          }}
          placeholderText={getPlaceholderText(locale)}
          selected={startDate}
          showTimeSelect
        />
      )}

      <ErrorMessage
        className="form_errorMessage"
        component="div"
        name={name}
      />
    </div>
  );
};

export default DatePickerComponent;

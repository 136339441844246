import React from 'react';

import clsx from 'clsx';

import Link from ':templates/partials/LinkHelper';

interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> { href?: string; }

const PrimaryButton = ({ className, ...props }: Props) => {
  const newClassName = clsx('btn is-style-primary py-12 px-32', className);

  if (!props.href) {
    return <button className={newClassName} {...props} />;
  }

  return (
    <Link
      className={newClassName}
      {...props}
    />
  );
};

export default PrimaryButton;

import React from 'react';

const CsFullWidthWrapper = (props) => {
  const { children } = props;

  return (
    <div className="customerSpotlight__container py-32 sm:py-40 md:py-48">
      {children}
    </div>
  );
};

export default CsFullWidthWrapper;

import React from 'react';

import { useRouter } from 'next/router';
import Cookies from 'universal-cookie';

import { getXHoursFromNow } from '../../helpers/date-helpers';
import LinkHelper from '../../templates/partials/LinkHelper';

const Button = (props) => {
  const router = useRouter();
  const cookies = new Cookies();
  const {
    attributes, propsOnClick, values,
  } = props;
  const {
    className, data, link, onClick, openInNewTab, text,
  } = attributes;

  const anchorProps = {
    className: `btn ${className}`,
    data: data,
    rel: openInNewTab && 'noopener noreferrer',
    target: openInNewTab && '_blank',
  };

  if (onClick) {
    var onClickFnc = new Function(
      'router',
      'cookies',
      'values',
      'getXHoursFromNow',
      onClick
    );
  }

  return (
    <LinkHelper
      anchorProps={anchorProps}
      data={data}
      href={link || undefined}
      onClick={() => {
        window.dataLayer.push({
          click_url: link,
          event: 'button_click',
        });

        if (propsOnClick) {
          propsOnClick();
        } else if (onClickFnc) {
          onClickFnc(
            null,
            router,
            cookies,
            values,
            getXHoursFromNow
          );
        }
      }}
    >
      <span>{text}</span>
    </LinkHelper>
  );
};

export default Button;

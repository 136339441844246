import React from 'react';

import Cookies from 'universal-cookie';

import { getXDayFromNow } from '../../helpers/date-helpers';
import { getIncrementValue, recursiveMap } from '../helpers';

const Trigger = ({
  activeIndex,
  activeProp,
  attributes,
  children,
  index,
  numOfItems,
  setActiveIndex,
}) => {
  const {
    className, styles, triggerEvents,
  } = attributes || {};
  let triggerVal;
  const eventsData = {};

  for (let {
    callback, customValFnc, explicitValue, increment, isToggle, name,
  } of triggerEvents) {
    explicitValue =
        typeof explicitValue === 'number'
          ? explicitValue.toString()
          : explicitValue;

    triggerVal = index;

    if (increment) {
      triggerVal = getIncrementValue({
        activeIndex,
        increment,
        numOfItems,
      });
    } else if (explicitValue !== undefined) {
      triggerVal = explicitValue;
    } else if (customValFnc) {
      triggerVal = new Function('values', customValFnc)({ activeIndex });
    }

    let toggledVal = triggerVal;

    if (isToggle && activeIndex === (explicitValue || index)) {
      toggledVal = null;
    }

    eventsData[name] = () => {
      setActiveIndex(toggledVal);

      if (callback) {
        const callbackFnc = new Function('values', callback);
        const cookies = new Cookies();

        callbackFnc({
          cookies,
          getXDayFromNow,
        });
      }
    };
  }

  const childrenWithProps = recursiveMap(children, (child) => {
    if (React.isValidElement(child)) {
      return React.cloneElement(child, {
        ...child.props,
        attributes: {
          ...child.props.attributes,
          data: {
            ...child.props.attributes?.data,
            [activeProp || 'data-active']:
              activeIndex === triggerVal || undefined,
          },
        },
      });
    }

    return child;
  });

  const activePropObj = { [activeProp || 'data-active']: activeIndex === triggerVal || undefined };

  return (
    <div
      className={`${className}`}
      style={styles}
      {...eventsData}
      {...activePropObj}
      data-trigger
    >
      {childrenWithProps}
    </div>
  );
};

export default Trigger;

import React, { useState } from 'react';
import { Waypoint } from 'react-waypoint';

const Sprite = ({ attributes }) => {
  const {
    className, fileName, skipLazyLoad,
  } = attributes;
  const [loaded, setLoaded] = useState(skipLazyLoad ? true : false);

  return (
    <Waypoint
      onEnter={() => {
        if (!loaded) {
          setLoaded(true);
        }
      }}
    >
      {loaded ? (
        <div
          className={`${className}`}
          style={{ backgroundImage: `url(https://cdn.rippling.com/static/marketing/site-content/files/icons/${fileName}.svg` }}
        />
      ) : undefined}
    </Waypoint>
  );
};

export default Sprite;

const SECONDS_IN_HOUR = 3600;
const SECONDS_IN_MINUTE = 60;

export const formatTimestamp = (timestamp: number) => {
  const date = new Date(timestamp);

  const month = String(date.getUTCMonth() + 1).padStart(2, '0');
  const day = String(date.getUTCDate()).padStart(2, '0');
  const year = date.getUTCFullYear();

  const hours = String(date.getUTCHours()).padStart(2, '0');
  const minutes = String(date.getUTCMinutes()).padStart(2, '0');
  const seconds = String(date.getUTCSeconds()).padStart(2, '0');

  return `${month}/${day}/${year} ${hours}:${minutes}:${seconds}`;
};

export const secondsToMinutes = (time: number) => {
  const minutes = Math.floor(time / SECONDS_IN_MINUTE);
  const seconds = time - minutes * SECONDS_IN_MINUTE;

  return [minutes, seconds];
};

export const secondsToHours = (time: number) => {
  const hours = Math.floor(time / SECONDS_IN_HOUR);
  time = time - hours * SECONDS_IN_HOUR;
  const [minutes, seconds] = secondsToMinutes(time);

  return [
    hours,
    minutes,
    seconds,
  ];
};

export const hoursToSeconds = (time: number) => {
  const hours = Math.floor(time);
  const minutes = (time - hours) * SECONDS_IN_MINUTE;
  const seconds = hours * SECONDS_IN_HOUR + minutes * SECONDS_IN_MINUTE;

  return Math.floor(seconds);
};

export const formatSeconds = (timeInSeconds: number, args: { format?: 'hours' | 'minutes' } = {}) => {
  const { format = 'hours' } = args;

  if (format === 'minutes') {
    const [minutes, seconds] = secondsToMinutes(timeInSeconds);

    return `${minutes}min ${seconds}s`;
  }
  const [hours, minutes] = secondsToHours(timeInSeconds);

  return `${hours}hr ${minutes}min`;
};

import {
  useCallback, useEffect, useMemo, useState
} from 'react';

import useQueryParam from ':hooks/useQueryParam';
import {
  format, isNotNull, pluralize
} from '@rippling/utils';
import useKeyboard from '@rippling/utils/hooks/useKeyboard';
import { isNumber, isString } from '@rippling/utils/validationUtils';
import clsx from 'clsx';
import differenceInDays from 'date-fns/differenceInDays';
import { LOCALES } from 'locales';
import Head from 'next/head';
import { useLocalStorage } from 'usehooks-ts';

import SectionBuilder from ':templates/SectionBuilder';

import PrimaryButton from './PrimaryButton';
import TableDataRow from './TableDataRow';

const STORAGE_KEY = 'entity_registration_ts';
const STORAGE_EXPIRATION_DAYS = 30;

interface FeeObject {
  // Always get added as-is
  baseValue?: number;
  eeMultiplier?: number;
  eeMultiplierRanges?: {
    max: number;
    multiplier: number
  }[];

  // Multiply against the eeMultiplier
  value?: number;
  valueCurrency?: string;
}

interface Props {
  attributes: {
    data: {
      data: {
        data: Record<
          string,
          | {
            // https://en.wikipedia.org/wiki/ISO_4217#List_of_ISO_4217_currency_codes
            currency: string;

            // BCP 47
            locale: string;
            setup_time: {
              entity: string;
              eor: string;
            };
          }
          | Record<
            string,
            {
              entity?: FeeObject;
              eor?: FeeObject;
            }
          >
        >;
        feeDisplay: {
          annual: string[];
          oneoff: string[];
        };
        text: Record<string, string>;
      };
    };
    form: unknown;
    fxRates: Record<string, number>;
  };
}

const getFxValue = ({
  fromCurrency,
  fxRates,
  toCurrency,
  value,
}: {
  fromCurrency: string;
  fxRates: Record<string, number>;
  toCurrency: string;
  value: number;
}) => {
  if (!value) {
    return value;
  }

  const fxRateKey = `${fromCurrency}:${toCurrency}`.toUpperCase();
  const fxRate = fxRates[fxRateKey] ?? 1;

  return value * fxRate;
};

const EntityRegistrationCostCalculator = ({
  attributes: {
    data: attributes, form, fxRates,
  },
}: Props) => {
  const [ungateQueryParam] = useQueryParam('ungate', '0');
  const [
    entityRegistrationTimestamp,
    setEntityRegistrationTimestamp,
    removeEntityRegistrationTimestamp,
  ] = useLocalStorage(
    STORAGE_KEY,
    null
  );

  const isUngated = ungateQueryParam === '1';
  const canSeeResults = useMemo(() => {
    if (isUngated) {
      return true;
    }

    if (!entityRegistrationTimestamp || !isNumber(entityRegistrationTimestamp)) {
      return false;
    }

    return Math.abs(differenceInDays(Date.now(), entityRegistrationTimestamp)) <= STORAGE_EXPIRATION_DAYS;
  }, [entityRegistrationTimestamp, isUngated]);

  const {
    data, feeDisplay, text,
  } = attributes.data;
  const countries = useMemo(() => Object.keys(data).sort(), [data]);

  const [country, setCountry] = useState('');
  const [employeeCount, setEmployeeCount] = useState<null | number>(null);
  const [isResultVisible, setIsResultVisible] = useState(false);
  const [isFormVisible, setIsFormVisible] = useState(false);

  const feesByCountry = country ? data[country] : {};

  const calculate = useCallback(({ feeObject = {} }: {
    emptyValue?: number | string;
    feeObject?: FeeObject;
  }) => {
    const {
      baseValue: usdBaseValue, eeMultiplier, eeMultiplierRanges = [], value,
    } = feeObject;

    let baseValue = usdBaseValue ?? 0;
    let baseFee = value ?? 0;

    if (!baseFee) {
      return baseFee;
    }

    if (eeMultiplier > 0 && employeeCount > 1) {
      baseFee += baseFee * eeMultiplier * (employeeCount - 1);
    } else if (eeMultiplierRanges?.length > 0) {
      const { multiplier } = eeMultiplierRanges.find(({ max }) => {
        if (max === undefined) {
          return true;
        }

        return employeeCount <= max;
      }) ?? { multiplier: 0 };

      baseFee = baseFee * multiplier;
    }

    const currency =
    feesByCountry.currency && isString(feesByCountry.currency)
      ? feesByCountry.currency
      : '';

    if (currency && feeObject.valueCurrency) {
      baseFee = getFxValue({
        fromCurrency: feeObject.valueCurrency,
        fxRates,
        toCurrency: currency,
        value: baseFee,
      });

      baseValue = getFxValue({
        fromCurrency: feeObject.valueCurrency,
        fxRates,
        toCurrency: currency,
        value: baseValue,
      });
    }

    return baseValue + baseFee;
  }, [employeeCount, fxRates]);

  const {
    totalAnnualEntityFee: aggregateAnnualEntityFee,
    totalAnnualEorFee: aggregateAnnualEorFee,
    totalOneoffEntityFee: aggregateOneoffEntityFee,
    totalOneoffEorFee: aggregateOneoffEorFee,
  } = useMemo(() => {
    let totalAnnualEorFee = 0;
    let totalOneoffEorFee = 0;

    let totalAnnualEntityFee = 0;
    let totalOneoffEntityFee = 0;

    for (const feeKey of feeDisplay.oneoff) {
      totalOneoffEntityFee += calculate({ feeObject: feesByCountry[feeKey]?.entity });
      totalOneoffEorFee += calculate({ feeObject: feesByCountry[feeKey]?.eor });
    }

    for (const feeKey of feeDisplay.annual) {
      totalAnnualEntityFee += calculate({ feeObject: feesByCountry[feeKey]?.entity });
      totalAnnualEorFee += calculate({ feeObject: feesByCountry[feeKey]?.eor });
    }

    return {
      totalAnnualEntityFee,
      totalAnnualEorFee,
      totalOneoffEntityFee,
      totalOneoffEorFee,
    };
  }, [
    calculate,
    country,
    feesByCountry,
  ]);

  const estimatedFirstYearEorFee =
    aggregateOneoffEorFee + aggregateAnnualEorFee;
  const estimatedFirstYearEntityFee =
    aggregateOneoffEntityFee + aggregateAnnualEntityFee;
  const recommendEor = estimatedFirstYearEorFee <= estimatedFirstYearEntityFee;

  const {
    entitySetupTime, eorSetupTime, formatCurrency,
  } = useMemo(() => {
    const currency =
      feesByCountry.currency && isString(feesByCountry.currency)
        ? feesByCountry.currency
        : 'USD';

    // Hardcoding because the page is only for US for now
    // In the future, if we need to format according to locale, we can use the locale from WP or system
    const formatter = new Intl.NumberFormat(LOCALES.EN_US, {
      currency,
      style: 'currency',
    });

    return {
      entitySetupTime:
        feesByCountry.setup_time?.entity &&
        isString(feesByCountry.setup_time.entity)
          ? feesByCountry.setup_time.entity
          : 'N/A',
      eorSetupTime:
        feesByCountry.setup_time?.eor && isString(feesByCountry.setup_time.eor)
          ? feesByCountry.setup_time.eor
          : 'N/A',
      formatCurrency: formatter.format,
    };
  }, [feesByCountry]);

  const formatOutput = useCallback(
    (value: number | string) => {
      if (isString(value)) {
        return value;
      }

      return formatCurrency(value);
    },
    [formatCurrency]
  );

  const { hideForm, showForm } = useMemo(() => {
    return {
      hideForm: () => {
        setIsFormVisible(false);
        document.body.style.overflow = 'auto';
      },
      showForm: () => {
        document.body.style.overflow = 'hidden';
        setIsFormVisible(true);
      },
    };
  }, []);

  const handleExpand = useCallback(() => {
    if (!canSeeResults) {
      showForm();
    }
  }, [canSeeResults, showForm]);

  const formBlocks = useMemo(() => {
    return [
      {
        ...form[0],
        attributes: {
          ...form[0].attributes,
          customOnSuccess: () => {
            setEntityRegistrationTimestamp(Date.now());
            hideForm();
          },
        },
      },
    ];
  }, [form, hideForm]);

  useKeyboard({
    enabled: isFormVisible,
    key: 'Escape',
    onPress: hideForm,
  });

  // Remove the localStorage if user can't see the results, but somehow has the timestamp
  useEffect(() => {
    if (isNotNull(entityRegistrationTimestamp) && !canSeeResults) {
      removeEntityRegistrationTimestamp();
    }
  }, [
    canSeeResults,
    entityRegistrationTimestamp,
    removeEntityRegistrationTimestamp,
  ]);

  return (
    <>
      <Head>
        <style
          dangerouslySetInnerHTML={{
            __html: `
              .cost-calculator-results + div {
                padding-top: 120px;
              }
            `,
          }}
        />
      </Head>
      {isFormVisible && !canSeeResults && (
        <div className="fixed top-70 left-0 right-0 bottom-0 z-5500 flex justify-center items-center">
          <div
            className="backdrop-filter backdrop-blur-md absolute top-0 left-0 w-full h-full bg-black/80 z-0"
            onClick={hideForm}
          />
          {/* @ts-expect-error Property 'locale' is missing */}
          <SectionBuilder blocks={formBlocks} />
        </div>
      )}
      <div
        className={clsx('w-full', isResultVisible && 'cost-calculator-results')}
      >
        <div className="relative flex justify-center">
          <div
            className={clsx(
              'flex flex-col gap-20 p-24 rounded-12 absolute z-1000 bg-white translate-y-[-50%] w-90% max-w-1125 shadow-lg',
              'md:flex-row xxsx:translate-y-[-40%]'
            )}
          >
            <div className="flex flex-col gap-8 flex-1">
              <span className="is-style-p1 font-medium text-plum-800">
                {text.iWantToHire}
              </span>
              <div className="select -abstract">
                <select
                  className={clsx(
                    'border-1 border-solid border-tertiary-gray2 rounded-4 bg-tertiary-gray1 hover:!border-plum-800 focus:!border-plum-800',
                    country ? 'text-plum-800' : 'text-plum-800/50'
                  )}
                  name="gender"
                  onChange={(e) => {
                    const newValue = e.target.value;

                    if (!newValue) {
                      setIsResultVisible(false);
                    }

                    setCountry(newValue);
                  }}
                  value={country}
                >
                  <option value="">{text.select}</option>
                  {countries.map((countryName) => (
                    <option key={countryName} value={countryName}>
                      {countryName}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="flex flex-col gap-8 flex-1">
              <span className="is-style-p1 font-medium text-plum-800">
                {text.numberOfEmployees}
              </span>
              <div className="flex-1">
                <input
                  className="border-1 border-solid border-tertiary-gray2 rounded-4 bg-tertiary-gray1 text-plum-800 w-full h-50 px-16 placeholder-opacity-50 outline-none hover:border-plum-800 focus:border-plum-800"
                  maxLength={10}
                  onChange={(e) => {
                    let newValue = null;
                    const value = e.target.value;

                    if (value) {
                      const num = Number.parseInt(e.target.value, 10);

                      if (!Number.isNaN(num) && num > 0) {
                        newValue = num;
                      }
                    }

                    if (!newValue) {
                      setIsResultVisible(false);
                    }

                    setEmployeeCount(newValue);
                  }}
                  placeholder="0"
                  value={employeeCount}
                />
              </div>
            </div>
            <div className="flex flex-col justify-end">
              <PrimaryButton
                className="w-1/2 xxsx:w-full md:w-full"
                disabled={!country || !employeeCount}
                onClick={() => {
                  setIsResultVisible(true);
                }}
                type="submit"
              >
                {text.calculate}
              </PrimaryButton>
            </div>
          </div>
          {isResultVisible && (
            <div
              className={clsx(
                'bg-tertiary-gray1 !m-20 rounded-20 w-full flex justify-center',
                'xsx:!m-0 xsx:rounded-0'
              )}
            >
              <div className="pt-200 md:pt-120 pb-50 max-w-1125 w-90%">
                <div className="max-w-[552px] mb-80 mx-auto flex flex-col items-center gap-21 text-center">
                  <div>
                    <div className="h5 is-style-overline w-100% smx:mx-auto">
                      {text.ourRecommendation}
                    </div>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: format(
                          text.resultTitle,
                          `<span class="text-plum-400">${employeeCount}</span>`,
                          pluralize(employeeCount, 'employee', 'employees'),
                          `<span class="text-plum-400">${country}</span>`,
                          recommendEor ? text.eor : text.entity
                        ),
                      }}
                      className="h2 text-plum-800"
                    />
                  </div>
                  {text.resultSubtitle && (
                    <div className="is-style-p1 text-plum-800/70">
                      {text.resultSubtitle}
                    </div>
                  )}
                  <PrimaryButton
                    className="max-w-min"
                    href={text.requestDemoLink}
                  >
                    {text.seeRippling}
                  </PrimaryButton>
                </div>
                <div>
                  {/* Title */}
                  <div className="xsx:sticky xsx:top-90 xsx:z-5000">
                    {/* Spacer - to hide things that scroll above the header */}
                    <div className="h-50 absolute -top-20 w-full bg-tertiary-gray1 sm:hidden" />
                    <div className="flex border-solid border-1 border-plum-600 rounded-tl-12 rounded-tr-12 bg-plum text-white font-medium h4">
                      <div className="flex items-center flex-1 px-32 py-16 h-88 xsx:hidden">
                        {text.comparison}
                      </div>
                      <div
                        className={clsx(
                          'flex justify-center items-center flex-1 px-32 py-16 h-88 border-solid border-l-1 border-r-1 border-plum-600',
                          'xsx:border-l-0'
                        )}
                      >
                        {text.eor}
                        {recommendEor && (
                          <span className="is-style-overline text-plum-800 absolute top-0 rounded-100 px-16 py-10 bg-plum-300 translate-y-[-50%]">
                            {text.recommended}
                          </span>
                        )}
                      </div>
                      <div className="flex justify-center items-center flex-1 px-32 py-16 h-88">
                        {text.entity}
                        {!recommendEor && (
                          <span className="is-style-overline text-plum-800 absolute top-0 rounded-100 px-16 py-10 bg-plum-300 translate-y-[-50%]">
                            {text.recommended}
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                  {/* oneoff */}
                  <TableDataRow
                    canSeeChildren={canSeeResults}
                    entityClassName="is-style-p1"
                    entityValue={formatOutput(aggregateOneoffEntityFee)}
                    eorClassName="is-style-p1"
                    eorValue={formatOutput(aggregateOneoffEorFee)}
                    onClick={handleExpand}
                    title={text.oneoff}
                    titleClassName="is-style-p1 font-medium"
                  >
                    {canSeeResults ?
                      feeDisplay.oneoff.map((feeKey, idx) => {
                        const entityValue = calculate({ feeObject: feesByCountry[feeKey]?.entity });
                        const eorValue = calculate({ feeObject: feesByCountry[feeKey]?.eor });

                        return (
                          <TableDataRow
                            key={`${feeKey}-${idx}`}
                            className={clsx(
                              'bg-tertiary-gray1',
                              idx % 2 !== 0 && 'xsx:bg-white'
                            )}
                            entityClassName="is-style-p2"
                            entityValue={formatOutput(entityValue || text.notApplicable)}
                            eorClassName="is-style-p2"
                            eorValue={formatOutput(eorValue)}
                            noBorder
                            title={text[feeKey]}
                            titleClassName="is-style-p2 font-medium xsx:!justify-start"
                          />
                        );
                      })
                      :
                      <div />
                    }
                  </TableDataRow>
                  {/* annual */}
                  <TableDataRow
                    canSeeChildren={canSeeResults}
                    entityClassName="is-style-p1"
                    entityValue={formatOutput(aggregateAnnualEntityFee)}
                    eorClassName="is-style-p1"
                    eorValue={formatOutput(aggregateAnnualEorFee)}
                    onClick={handleExpand}
                    title={text.annual}
                    titleClassName="is-style-p1 font-medium"
                  >
                    {canSeeResults ?
                      feeDisplay.annual.map((feeKey, idx) => {
                        const entityValue = calculate({ feeObject: feesByCountry[feeKey]?.entity });
                        const eorValue = calculate({ feeObject: feesByCountry[feeKey]?.eor });

                        return (
                          <TableDataRow
                            key={`${feeKey}-${idx}`}
                            className={clsx(
                              'bg-tertiary-gray1',
                              idx % 2 !== 0 && 'xsx:bg-white'
                            )}
                            entityClassName="is-style-p2"
                            entityValue={formatOutput(entityValue || text.notApplicable)}
                            eorClassName="is-style-p2"
                            eorValue={formatOutput(eorValue)}
                            noBorder
                            title={text[feeKey]}
                            titleClassName="is-style-p2 font-medium xsx:!justify-start"
                          />
                        );
                      })
                      :
                      <div />
                    }
                  </TableDataRow>
                  {/* Estimated year 1 */}
                  <TableDataRow
                    entityClassName="text-16 leading-22 font-medium sm:text-24 sm:leading-32 sm:font-bold"
                    entityValue={formatOutput(estimatedFirstYearEntityFee)}
                    eorClassName="text-16 leading-22 font-medium sm:text-24 sm:leading-32 sm:font-bold"
                    eorValue={formatOutput(estimatedFirstYearEorFee)}
                    title={text.estimatedYearOne}
                    titleClassName="is-style-p1 font-medium"
                  />
                  {/* Estimated setup time */}
                  <TableDataRow
                    className="bg-white rounded-bl-12 rounded-br-12 border-solid border-b-1 border-tertiary-gray2"
                    entityClassName="text-16 leading-22 font-medium sm:text-24 sm:leading-32 sm:font-bold border-b-0"
                    entityValue={entitySetupTime}
                    eorClassName="text-16 leading-22 font-medium sm:text-24 sm:leading-32 sm:font-bold border-b-0"
                    eorValue={eorSetupTime}
                    title={text.estimateSetupTime}
                    titleClassName="is-style-p1 font-medium border-b-0"
                  />
                </div>
                {text.resultTableNotes && (
                  <p className="mt-32 mx-auto text-plum-800/70 is-style-p2 text-center w-70%">
                    {text.resultTableNotes}
                  </p>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default EntityRegistrationCostCalculator;

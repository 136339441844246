import Image from '../../components/basic/Image';
import Sprite from './Sprite';

export const recipeTemplatePriorities = {
  'formula-field': 1,
  report: 2,
  survey: 4,
  'workflow-automation': 3,
};

export const getTileImages = (products, integrations, size) => {
  const result = [];
  const plusImage = (
    <div key="plus" className="mx-10">
      <Image
        attributes={{
          height: 12,
          layout: 'fixed',
          mediaURL:
            'https://cdn.rippling.com/static/marketing/site-content/files/icons/plus-gray.svg',
          width: 12,
        }}
      />
    </div>
  );
  if (products.length > 1) {
    products = products.filter((product) => product._slug !== 'hris');
  }

  for (const [i, product] of products.entries()) {
    if (i !== 0) {
      result.push(plusImage);
    }

    const Comp = (
      <Sprite
        key={`prod-${i}`}
        attributes={{
          className: `${product._image} s-exp-common w-${size} h-${size}`,
          fileName: 'sprite-expressive-11',
          skipLazyLoad: true,
        }}
      />
    );
    result.push(Comp);
  }

  for (let i = 0; i < (integrations || []).length; i++) {
    const integration = integrations[i];
    result.push(plusImage);

    const Comp = (
      <div
        key={`int-${i}`}
        className={`${/slack|jira|google-workspace|webhooks|carta/.test(integration._image) ? 'bg-white border-1 border-solid border-darker-eggshell rounded-10' : ''}`}
      >
        <Sprite
          attributes={{
            className: `${integration._image} s-exp-common w-${size} h-${size}`,
            fileName: 'sprite-expressive-11',
            skipLazyLoad: true,
          }}
        />
      </div>
    );
    result.push(Comp);
  }

  return <div className="flex items-center">{result}</div>;
};

export const slugify = (name) =>
  name &&
    name
      .toLowerCase()
      .replaceAll(/\(|\)|\s\/|,|’|'/g, '')
      .replaceAll('/', ' ')
      .replaceAll(/\s/g, '-')
      .replaceAll('&', 'and') ||
  null;

import React from 'react';

import Image from '../basic/Image';
import Wistia from '../basic/Wistia';

const CsQuote = (props) => {
  const { attributes } = props;
  const {
    authorImageUrl,
    authorName,
    authorRole,
    imageUrl,
    layoutType,
    quote,
    wistiaID,
  } = attributes;

  const hasMedia = Boolean(wistiaID || imageUrl);

  let finalClassName = '';

  if (layoutType !== 'inline' && layoutType !== 'inline-smaller-font') {
    finalClassName =
      layoutType === 'full-width' ? 'w-93%' : 'customerSpotlight__container';
  }

  return (
    <div className={`relative ${layoutType === 'inline-smaller-font' ? 'my-32 sm:my-40 md:my-48' : 'my-48 sm:my-64 md:my-80'}`}>
      <div className={`${finalClassName} mx-auto px-1 sm:px-0`}>
        <div
          className={`${
            hasMedia
              ? 'items-center flex-col md:flex-row gap-0 md:gap-60 row'
              : ''
          } row`}
        >
          {hasMedia && (
            <div className="column w-100% md:w-4/12 flex-1 mb-24 md:mb-0 max-w-100%">
              {imageUrl ? (
                <div className="imageX">
                  <Image
                    attributes={{
                      className: 'w-100%',
                      height: 408,
                      mediaURL: imageUrl,
                      nonResponsive: true,
                      width: 408,
                    }}
                  />
                </div>
              )
                : (
                  <div className="w-100% max-w-100%">
                    <Wistia attributes={{ wistiaId: wistiaID }} />
                  </div>
                )}
            </div>
          )}
          <div
            className={`${
              hasMedia
                ? 'pb-32 w-100% sm:w-7/12 smx:w-100% sm:pb-0 flex flex-wrap flex-col-reverse sm:flex-col'
                : 'w-100% mx-auto'
            } column`}
          >
            <div className="column pb-24">
              <div className={`float-left ${layoutType === 'inline-smaller-font' ? 'mr-4 w-24 h-24 top-5' : 'mr-8 w-38 h-38 md:w-48 md:h-46 top-2'}`}>
                <Image
                  attributes={{
                    className: 'mt-0 mb-0',
                    mediaURL: 'https://rippling2.imgix.net/plum-quote.svg',
                    nonResponsive: true,
                  }}
                />
              </div>
              {layoutType === 'inline-smaller-font' ? (
                <h3 className="text-primary-plum">
                  {quote}
                </h3>
              ) : (
                <p className="font-bold text-plum-800 -tracking-150 font-display mt-0 text-36 md:-tracking-100 md:leading-48 leading-39 -letter-150 md:text-48 mb-20 md:mb-24">
                  {quote}
                </p>
              )}
            </div>
            <div className="jusify-start flex items-center flex-nowrap">
              {/* If we don't have media (full width) */}
              {/* or we don't have authorImageURL */}
              {!hasMedia && Boolean(authorImageUrl) && (
                <div className="mr-16 md:w-64 md:h-64 w-48 h-48 rounded-50% overflow-hidden">
                  <Image
                    attributes={{
                      className: 'block',
                      height: 64,
                      mediaURL: authorImageUrl,
                      nonResponsive: true,
                      width: 64,
                    }}
                  />
                </div>
              )}
              <div className="flex w-100% flex-col sm:flex-row flex-nowrap md:w-100% md:pb-0 flex-1">
                <p className="font-medium is-style-overline leading-16 mr-4 md:mr-8 text-12 uppercase !mb-0 sm:w-auto">
                  {authorName}
                </p>
                <p className="font-medium is-style-overline leading-16 text-12 text-black/70 uppercase w-auto">
                  {authorRole}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CsQuote;
